<template>
  <TableStyleWrapper>
    <TableWrapper>
      <a-table
        :columns="visibleColumns"
        :data-source="dataSource ? dataSource.data : []"
        :rowKey="(record) => record._jv.id"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1500 }"
        >
        <template #descriptionOfGoods="{ record }">
            <div class="preserve-multiline">{{record.descriptionOfGoods}}</div>
        </template>
        <template #valuePerItem="{record}">
          {{$_.round(record.valuePerItem, 2)}}
        </template>
        <template #totalValue="{ record }">
          <div class="preserve-multiline">{{$_.round(record.quantity * record.valuePerItem, 2)}}</div>
        </template>

        <template #productCategory="{ record }">
            <div class="preserve-multiline">{{record.productCategory.value}}</div>
        </template>

        <template #comment="{ record }">
            <div class="preserve-multiline">{{record.comment}}</div>
        </template>

        <template #actions="{record}" >
          <sd-button
            class="download-sheet-btn"
            v-if="record.dataSheet"
            type="default"
            shape="circle"
            :tooltip="$t('actions.download')"
            @click="download(record)">
            <sd-featherIcons class="download-text-icon" type="download" size="16"/>
           <span class="download-text-btn">{{record.dataSheet.filename}}</span>
          </sd-button>
        </template>
      </a-table>
    </TableWrapper>
  </TableStyleWrapper>
</template>

<script>
import {
  computed, defineComponent
} from 'vue';
import {usePagination} from 'vue-request';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';
import {downloadFile} from '@/helpers/downloadFile';

export default defineComponent({
  name: 'ItemsTable',
  components: {
    TableStyleWrapper, TableWrapper
  },
  props: {
    shipmentId: VueTypes.string,
    shipmentRequestId: VueTypes.string,
    isClient: VueTypes.bool,
    isBackOffice: VueTypes.bool

  },
  emits: ['searched'],
  setup(props) {
    const store = useStore();
    const {t} = useI18n();

    const columns = computed(() =>[
      {
        title: t('models.productItem.attributes.hsCode'),
        dataIndex: 'hsCode',
        visible: true,
        width: 100
      },
      {
        title: t('models.productItem.attributes.totalValue'),
        dataIndex: 'total',
        visible: true,
        width: 100,
        slots: {customRender: 'totalValue'}
      },
      {
        title: t('models.productItem.attributes.quantity'),
        dataIndex: 'quantity',
        visible: true,
        width: 150
      },
      {
        title: t('models.productItem.attributes.valuePerItem'),
        dataIndex: 'valuePerItem',
        slots: {customRender: 'valuePerItem'},
        visible: true,
        width: 150
      },
      {
        title: t('models.productItem.attributes.productNumber'),
        dataIndex: 'productNumber',
        visible: true,
        width: 100
      },
      {
        title: t('models.productItem.attributes.productCategory'),
        dataIndex: 'productCategory',
        slots: {customRender: 'productCategory'},
        visible: true,
        width: 200
      },
      {
        title: t('models.productItem.attributes.descriptionOfGoods'),
        dataIndex: 'descriptionOfGoods',
        slots: {customRender: 'descriptionOfGoods'},
        visible: true,
        width: 200
      },
      {
        title: t('models.productItem.attributes.countryOfOrigin'),
        dataIndex: 'countryOfOriginName',
        visible: true,
        width: 150
      },
      {
        title: t('models.productItem.attributes.comment'),
        dataIndex: 'comment',
        slots: {customRender: 'comment'},
        visible: props.isClient || props.isBackOffice,
        width: 200
      },
      {
        title: t('models.productItem.attributes.itemType'),
        dataIndex: 'itemType',
        visible: true,
        width: 200
      },
      {
        title: t('models.productItem.attributes.dataSheet'),
        slots: {customRender: 'actions'},
        visible: true,
        width: 150
      }
    ]);

    const visibleColumns = computed(() => columns.value.filter((column) => column.visible));

    const getItems = (params) => store.dispatch(props.isBackOffice ? 'getAffectedShipmentItems' : 'getShipmentItems', {
      ...params, shipmentId: props.shipmentId, shipmentRequestId: props.shipmentRequestId
    });

    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize
    } = usePagination(getItems, {
      formatResult: () => store.getters.shipmentItems,
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'perPage',
        totalKey: 'totalEntries'
      }
    });

    const pagination = computed(() => ({
      total: store.getters.shipmentItems?.totalEntries,
      current: current.value,
      pageSize: pageSize.value
    }));

    const handleTableChange = (pag, filters, sorter) => {
      run({
        per_page: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters
      });
    };

    const download = (record) => downloadFile(record.dataSheet.filename, record.dataSheet.url);

    return {
      visibleColumns,
      dataSource,
      pagination,
      loading,
      handleTableChange,
      download
    };
  }
});
</script>

<style lang="scss" scoped>
table thead tr th:last-child {
  text-align: center !important;
}
</style>
<style scoped>
.download-text-btn{
  color: #1890ff;
  padding-left: 10px!important;
  padding-right: 10px;
  width: 100px;
  overflow: hidden!important;
  text-overflow: ellipsis;
}
.download-text-icon{
  padding-left: 10px!important;
  padding-right: 10px;
}
</style>
<style lang="scss">
.download-sheet-btn{
  .igtKCI{
    padding: 0px 0px!important;
  }
}
</style>
