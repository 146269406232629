<template>
  <TableStyleWrapper>
    <TableWrapper class="table-responsive">
      <a-table
        :columns="columns"
        :data-source="records || []"
        rowKey="key"
        :pagination="false"
      />
    </TableWrapper>
  </TableStyleWrapper>
</template>

<script>
import {
  computed, defineComponent, toRefs
} from 'vue';
import {useI18n} from 'vue-i18n';
import VueTypes from 'vue-types';
import _ from 'lodash';
import {TableStyleWrapper} from '../tables/style';
import {TableWrapper} from '@/components/shared/styledComponents/Main';

export default defineComponent({
  name: 'DimensionsAndWeightsTable',
  components: {
    TableStyleWrapper, TableWrapper
  },
  props: {
    data: VueTypes.object
  },
  setup(props) {
    const {t} = useI18n();
    const {data} = toRefs(props);
    const records = computed(() => _.map(data.value, (record, key) => {
      return {...record, key};
    }));

    const columns = computed(() =>[
      {
        title: t('models.shipment.attributes.length'),
        dataIndex: 'length'
      },
      {
        title: t('models.shipment.attributes.width'),
        dataIndex: 'width'
      },
      {
        title: t('models.shipment.attributes.height'),
        dataIndex: 'height'
      },
      {
        title: t('models.shipment.attributes.dimensionsUnit'),
        dataIndex: _.snakeCase('dimensionsUnit')
      },
      {
        title: t('models.shipment.attributes.weight'),
        dataIndex: 'weight'
      },
      {
        title: t('models.shipment.attributes.weightUnit'),
        dataIndex: _.snakeCase('weightUnit')
      },
      {
        title: ''
      }
    ]);

    return {
      columns,
      records
    };
  }
});
</script>

<style lang="scss" scoped>
table thead tr th:last-child {
  text-align: center !important;
}
</style>
