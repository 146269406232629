import { render } from "./ItemsTable.vue?vue&type=template&id=6742a899&scoped=true"
import script from "./ItemsTable.vue?vue&type=script&lang=js"
export * from "./ItemsTable.vue?vue&type=script&lang=js"

import "./ItemsTable.vue?vue&type=style&index=0&id=6742a899&lang=scss&scoped=true"
import "./ItemsTable.vue?vue&type=style&index=1&id=6742a899&scoped=true&lang=css"
import "./ItemsTable.vue?vue&type=style&index=2&lang=scss"
script.render = render
script.__scopeId = "data-v-6742a899"

export default script