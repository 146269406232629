<template>
  <modal-form
    :title="$t('actions.requestDocuments')"
    :fields="fields"
    :rules="rules"
    :loading="loading"
    :visible="visible" :cancelButton="cancelButton"
    v-model:resetForm="resetForm"
    @close-modal="closeModal" @submit-form="handleSubmit"
  />
  <div>
    <app-header
    :routes="routes"
    :buttons="buttons"
    @header-action="headerAction($event)" />
    <Main>
      <a-row :gutter="15">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="details" :tab="$t('views.shipmentRequest.shipmentDetails')">
            <a-row :gutter="15">
              <a-col
                :xs="24"
                :md="allowViewDocuments ? 16 : 24"
                :lg="allowViewDocuments ? 18 : 24"
              >
                <shipment-card
                  v-if="shipmentRequest && shipment"
                  :shipment="shipment" :shipmentRequest="shipmentRequest"
                  :rejectionReason="rejectionReason"
                  :canRequestDocuments="false" :view="'full'" :headless="true"
                />
              </a-col>
              <a-col
                :xs="allowViewDocuments ? 24 : 0"
                :md="allowViewDocuments ? 8 : 0"
                :lg="allowViewDocuments ? 6 : 0"
                class="multi-card-col"
              >
                <shipment-tracking
                  v-if="!isClient && shipment?.agentId === +companyId ||
                    (isClient && !$_.isEmpty(shipment.trackingStatus))"
                  :canUpdate="canUpdateTrackingStatus(can, shipment)"
                  :shipment="shipment"
                  :isClient="isClient"
                />
                <file-list-card
                  class="file-list-card"
                  v-if="allowViewDocuments"
                  :files="shipment.documents || []"
                  :title="$t('models.shipment.attributes.documents')">
                  <template v-if="!isClient" v-slot:buttons>
                    <sdButton v-if="$_.has(shipmentQuote, 'status') &&
                                    canModifyQuote(shipmentQuote, shipment)"
                              shape="circle" class="btn-icon" type="default"
                              @click="visible = true" :tooltip="$t('actions.requestDocuments')" >
                      <sdFeatherIcons type="edit" size="16" />
                    </sdButton>
                  </template>
                </file-list-card>
              </a-col>
              <a-col :xs="24" class="mb-30">
                <sdCards :title="$t('models.shipment.attributes.dimensionsAndWeights')">
                  <dimensions-and-weights-table :data="shipment ? shipment.dimensionsAndWeights : []" />
                </sdCards>
              </a-col>
              <a-col :xs="24">
                <sdCards :title="$t('models.shipmentItem.entity', 2)">
                  <items-table
                    v-if="shipment._jv"
                    :shipmentId="shipmentId"
                    :shipmentRequestId="shipmentRequestId"
                    :isClient="isClient"
                    :isBackOffice="isBackOffice"
                  />
                </sdCards>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane v-if="canViewHistory" key="history"
          :tab="$t('views.shipmentRequest.shipmentHistory')">
            <a-col :xxl="24" :xl="24" :md="24" :xs="24">
              <sd-cards :title="$t('layout.shipmentRequest.history')">
                <versions-table resourceName="shipment" :resourceId="shipmentId"/>
              </sd-cards>
            </a-col>
          </a-tab-pane>
        </a-tabs>
      </a-row>
    </Main>
  </div>
  <client-agent-chat v-if="shipmentQuote._jv && $can('manage', 'chat')"
                     :quote-id="shipmentQuote._jv?.id"
                     :quote-status="shipmentQuote?.status"/>
</template>

<script>
import {
  defineComponent, computed, ref, reactive, onBeforeMount, watch, onUnmounted
} from 'vue';
import {Main} from '@/components/shared/styledComponents/Main';
import ModalForm from '@/components/modals/ModalForm';
import ShipmentCard from '@/components/shipments/ShipmentCard';
import FileListCard from '@/components/shared/cards/FileListCard';
import {useStore} from 'vuex';
import {useRoute, useRouter} from 'vue-router';
import {agentShipmentStatus, shipmentQuoteStatuses} from '@/constants';
import {useI18n} from 'vue-i18n';
import ItemsTable from '@/components/shipments/ItemsTable';
import ShipmentTracking from '@/components/shipments/ShipmentTracking';
import DimensionsAndWeightsTable from '@/components/shipments/DimensionsAndWeightsTable';
import ClientAgentChat from '@/components/shipmentQuotes/ClientAgentChat';
import {
  canViewDocuments, canModifyQuote, canUpdateTrackingStatus
} from '@/helpers/quoteActionsConditions';
import {useAbility} from '@casl/vue';
import VersionsTable from '@/components/shipments/VersionsTable';
import {downloadFile} from '@/helpers/downloadFile';
import {previewFile} from '@/helpers/previewFile';
import _ from 'lodash';

export default defineComponent({
  name: 'ShipmentDetails',
  components: {
    Main, ShipmentCard, FileListCard, ModalForm, ItemsTable, ShipmentTracking, DimensionsAndWeightsTable,
    ClientAgentChat, VersionsTable
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const {t} = useI18n();

    const isInvoiceButtonsVisible = computed(() => {
      return (isClient.value || isBackOffice.value) && shipment.value.invoice;
    });

    const buttons = computed(() => {
      const allButtons = [
        {
          action: 'viewQuotes',
          icon: 'book-open',
          label: 'views.shipmentRequest.viewQuotes',
          visible: isClient.value || isBackOffice.value || can('read', shipmentQuote.value)
        },
        {
          action: 'previewInvoicePdf',
          icon: 'navigation',
          label: 'views.shipment.previewInvoice',
          visible: isInvoiceButtonsVisible.value
        },
        {
          action: 'downloadInvoicePdf',
          icon: 'download',
          label: 'views.shipment.downloadInvoice',
          visible: isInvoiceButtonsVisible.value
        }
      ];
      return allButtons.filter((button) => button.visible);
    });

    const viewQuotes = () => {
      let routeName;
      if (isClient.value) {
        routeName = 'ClientShipmentQuotes';
      } else if (isBackOffice.value) {
        routeName = 'BackOfficeShipmentQuotes';
      } else {
        routeName = 'AgentShipmentQuotes';
      }
      router.push({
        name: routeName,
        params: {
          shipmentRequestId: shipment.value.shipmentRequestId,
          shipmentId: shipment.value._jv.id
        }
      });
    };
    const headerAction = (action) => headerActions[action]();
    const headerActions = {
      viewQuotes: () => viewQuotes(),
      previewInvoicePdf: () => previewInvoicePdf(),
      downloadInvoicePdf: () => downloadInvoicePdf()
    };
    const previewInvoicePdf = () => {
      const invoicePdfUrl = shipment.value.invoice.invoicePdf.url;
      previewFile(invoicePdfUrl);
    };
    const downloadInvoicePdf = () =>{
      const invoicePdf = shipment.value.invoice.invoicePdf;
      downloadFile(invoicePdf.filename, invoicePdf.url);
    };

    const isClient = computed(() => store.state.session.userType === 'ClientUser');

    const companyId = computed(() => store.state.session.currentUser?._jv?.relationships.company?._jv.id);
    const allowViewDocuments = computed(() => canViewDocuments(can, shipment) || isClient || isBackOffice);
    const isBackOffice = computed(() => store.state.session.userType === 'BackOfficeUser');
    const accessToUserType = !isBackOffice.value && !isClient.value;
    const shipmentRequestId = ref();
    const shipmentId = ref();
    const activeKey = ref('details');
    const getShipment = async () => {
      if (shipmentRequestId.value && shipmentId.value) {
        await store.dispatch('toggleLoading', true);
        if (isBackOffice.value) {
          await store.dispatch('getAffectedShipment', {shipmentId: shipmentId.value});
        } else {
          await store.dispatch('getShipment', {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value});
        }
        if (accessToUserType && shipment.value.agentMainQuote.status !== shipmentQuoteStatuses.WAITING_AGENT_RESPONSE) {
          await store.dispatch('getQuoteByShipment', {
            shipmentId: shipmentId.value, shipmentRequestId: shipmentRequestId.value,
            params: {fields: ['documentsRequested']} // check why not only this field is returned
          });
          fields.documentsRequested.fieldValue = shipmentQuote.value.documentsRequested;
          fields.documentsRequested['forceUpdate'] = true;
        }
        await store.dispatch('toggleLoading', false);
      }
    };

    const rejectionReason = computed(() => {
      if (!isClient.value && shipment.value._jv &&
          (shipment.value.agentShipmentStatus === agentShipmentStatus.REJECTED ||
          shipment.value.agentShipmentStatus === agentShipmentStatus.REJECTION_WAITING_APPROVAL)) {
        return shipment.value.rejectionByAgent.reason;
      } else {
        return undefined;
      }
    });

    const currentCompanyId = computed(() => store.state.session.currentUser._jv?.relationships.company._jv.id);
    const shipment = computed(() => store.getters.shipment);
    const shipmentRequest = computed(() => shipment.value?._jv?.relationships?.shipmentRequest);
    const shipmentQuote = computed(() => store.getters.shipmentQuote);

    const routes = computed(() => {
      if (isBackOffice.value) {
        return [{
          name: 'BackOfficeShipments',
          query: {currentTab: route.query.currentTab},
          breadcrumbName: 'views.shipmentRequest.shipments'
        },
        {
          name: 'BackOfficeShipmentDetails',
          params: {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value},
          breadcrumbName: 'views.shipmentRequest.shipment'
        }];
      } else {
        const breadcrumbs = [
          {
            name: isClient.value ? 'ClientShipmentRequests' : 'AgentShipments',
            breadcrumbName: isClient.value ? 'layout.shipmentRequest.index' : 'layout.shipmentRequest.shipments'
          },
          {
            name: isClient.value ? 'ClientShipmentDetails' : 'AgentShipmentDetails',
            params: {shipmentRequestId: shipmentRequestId.value, shipmentId: shipmentId.value},
            breadcrumbName: 'views.shipmentRequest.shipment'
          }
        ];

        if (isClient.value) {
          breadcrumbs.splice(1, 0, {
            name: isClient.value ? 'ClientShipmentRequestDetails' : 'AgentShipmentRequestDetails',
            params: {shipmentRequestId: shipmentRequestId.value},
            breadcrumbName: 'views.shipmentRequest.details'
          });
        }

        return breadcrumbs;
      }
    });

    const visible = ref(false);
    const cancelButton = {type: 'secondary', visible: true};
    const openModal = () => visible.value = true;
    const fields = reactive({
      documentsRequested: {
        type: 'textArea',
        label: '',
        fieldValue: ''
      }
    });
    const rules = reactive({
      documentsRequested: {
        required: true,
        message: t('messages.error.required'), trigger: 'change'
      }
    });

    const loading = ref(false);
    const handleSubmit = (data) => {
      loading.value = true;
      store.dispatch('updateShipmentQuote', {
        id: shipmentQuote.value._jv.id, form: data, updateType: 'request'
      }).then(({status}) => {
        loading.value = false;
        if (status === 200) visible.value = false;
      });
    };

    const resetForm = ref(false);
    const closeModal = () => {
      fields.documentsRequested.fieldValue = shipmentQuote.value.documentsRequested || '';
      fields.documentsRequested['forceUpdate'] = true;
      visible.value = false;
    };

    watch(() => route.params, () => {
      if (route.name === 'AgentShipmentDetails' || route.name === 'ClientShipmentDetails' || route.name === 'BackOfficeShipmentDetails') {
        shipmentRequestId.value = route.params.shipmentRequestId || route.query.shipmentRequestId;
        shipmentId.value = route.params.shipmentId;
        getShipment();
      }
    }, {immediate: true, deep: true});

    watch(shipmentQuote, (shipmentQuoteValue) => {
      if (shipmentQuoteValue.status !== shipmentQuoteStatuses.REJECTED && !isClient.value) {
        store.dispatch('subscribeToMessagesChannel', shipmentQuoteValue._jv.id);
      }
    });

    onBeforeMount(() => store.dispatch('clearChat'));
    onUnmounted(() => {
      if (!isClient.value) {
        store.dispatch('unsubscribeToMessagesChannel', shipmentQuote.value._jv?.id);
      }
    });

    const {can} = useAbility();
    const canViewHistory = computed(() => {
      return can('read', 'shipmentRequestHistory') && !_.includes(['rejected', 'archived'], shipment.value?.agentMainQuote?.status);
    });

    return {
      buttons,
      headerAction,
      routes,
      isClient,
      isBackOffice,
      shipmentRequestId,
      shipmentId,
      shipment,
      shipmentRequest,
      shipmentQuote,
      shipmentQuoteStatuses,
      cancelButton,
      visible,
      fields,
      loading,
      rules,
      currentCompanyId,
      rejectionReason,
      resetForm,
      openModal,
      closeModal,
      handleSubmit,
      can,
      canViewDocuments,
      canModifyQuote,
      canUpdateTrackingStatus,
      activeKey,
      allowViewDocuments,
      companyId,
      canViewHistory
    };
  }
});
</script>

<style lang="scss" scoped>
.hutSpt {
  height: 100% !important;
  padding-bottom: 25px !important;
}

.multi-card-col {
  display: flex !important;
  flex-flow: column !important;
}

.file-list-card.file-list-card {
  flex-grow: 1 !important;
  margin-bottom: 25px !important;
}

.ant-card.eHvyVL {
  height: 100% !important;
}
</style>
