<template>
  <modal-form :title="$t('views.shipmentRequest.setTrackingStatus')"
              :fields="fields"
              :rules="rules"
              :classes="'tracking-modal-form'"
              :loading="loading"
              :visible="modalVisible" :cancelButton="cancelButton"
              @forceUpdated="handleForceUpdate"
              @close-modal="closeModal" @submit-form="submitForm"/>
  <sdCards :title="$t('views.shipmentRequest.trackingStatus')">
      <a-row type="flex">
        <a-col flex="auto" class="status-col">
          <h3 v-if="shipment.trackingStatus" class="tracking-status">
            {{shipment.trackingStatus.value}}
          </h3>
          <p v-else-if="canUpdate">
            {{$t('views.shipmentRequest.setTrackingStatus')}}
          </p>
        </a-col>
        <a-col :flex="canUpdate ? '50px' : '0px'">
          <sdButton
            v-if="canUpdate"
            class="btn-icon"
            type="default"
            shape="circle"
            :disabled="shipment.pendingCancellation"
            :tooltip="shipment.pendingCancellation ? $t('messages.help.shipmentPendingCancellation') :
              $t('actions.edit')"
            @click="modalVisible = true"
          >
            <sdFeatherIcons type="edit" size="16" />
          </sdButton>
        </a-col>
      </a-row>
  </sdCards>
</template>

<script>
import {
  defineComponent, ref, reactive, computed
} from 'vue';
import ModalForm from '@/components/modals/ModalForm';
import VueTypes from 'vue-types';
import {useStore} from 'vuex';
import {useI18n} from 'vue-i18n';
import _ from 'lodash';
import {extractActiveListOptions} from '@/helpers/dynamicListsOptions';

export default defineComponent({
  name: 'ShipmentTracking',
  components: {ModalForm},
  props: {
    shipment: VueTypes.object,
    isClient: VueTypes.bool,
    canUpdate: VueTypes.bool
  },
  setup(props) {
    const {dispatch, getters} = useStore();
    const {t} = useI18n();
    dispatch('getList', 'TRACKING_STATUS');

    const fields = reactive({
      statusId: {
        fieldValue: props.shipment.trackingStatus ? `${props.shipment.trackingStatus.id}` : '',
        type: 'select',
        options: computed(() => {
          return extractActiveListOptions(getters.trackingStatuses, props.shipment, 'trackingStatus.id');
        })
      }
    });

    const rules = {
      statusId: [{
        required: true, message: t('messages.error.required'), trigger: 'blur'
      }]
    };

    const cancelButton = {type: 'secondary', visible: true};
    const modalVisible = ref(false);

    const loading = ref(false);
    const submitForm = (data) => {
      loading.value = true;
      dispatch('updateTrackingStatus', {
        form: data,
        shipmentId: props.shipment._jv.id,
        shipmentRequestId: props.shipment.shipmentRequestId
      }).then((status) => {
        loading.value = false;
        setTrackingStatus();
        if (status === 200) modalVisible.value = false;
      });
    };

    const closeModal = () => {
      modalVisible.value = false;
      setTrackingStatus();
    };

    const setTrackingStatus = () => {
      fields.statusId.fieldValue = props.shipment.trackingStatus ? `${props.shipment.trackingStatus.id}` : undefined;
      fields.statusId['forceUpdate'] = true;
    };

    const handleForceUpdate = (keys) => _.forEach(keys, (key) => fields[key]['forceUpdate'] = false);

    return {
      fields,
      rules,
      cancelButton,
      modalVisible,
      loading,
      submitForm,
      handleForceUpdate,
      closeModal
    };
  }
});
</script>

<style lang="scss" scoped>
.tracking-status,
p {
  position: relative;
  top: 25%;
  margin-bottom: 0;
}

.status-col {
  min-height: 35px;
}

h3 {
  text-align: center;
}
</style>
